// src/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import fondosol from './fondosol.png';
import './Login.css';

const Login = () => {
  const [user, setUser] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const db = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const q = query(collection(db, 'inspirados'), where('user', '==', user));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Usuario encontrado
        const userData = querySnapshot.docs[0].data();
        localStorage.setItem('user', user); // Guardar el usuario en localStorage
        navigate('/cuenta-personal', { state: { userId: querySnapshot.docs[0].id, userName: userData.nombre } }); // Pasar el ID del usuario
      } else {
        // Usuario no encontrado
        setError('Usuario no encontrado');
      }
    } catch (error) {
      setError('Error al iniciar sesión');
    }
  };

  return (
    <div className="login-container" style={{ backgroundImage: `url(${fondosol})` }}>
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleLogin}>
        <input 
          type="text" 
          placeholder="Usuario" 
          value={user} 
          onChange={(e) => setUser(e.target.value)} 
          required 
        />
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="login-button">Iniciar Sesión</button>
      </form>
    </div>
  );
};

export default Login;
