import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db, auth } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

const HistorialTrueques = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getHistorialTrueques();
  }, []);

  const getHistorialTrueques = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'historialtrueques'));
      const historialData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        fechafin: doc.data().fechafin ? new Date(doc.data().fechafin.seconds * 1000) : null,
      }));
      setData(historialData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <Row className="mb-3">
          <Col>
            <Button variant="danger" onClick={handleSignOut}>Cerrar Sesión</Button>
          </Col>
        </Row>
        <br />
        <h3>Historial de Trueques</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Propietario</th>
              <th>Ofertante</th>
              <th>Objeto</th>
              <th>Fecha de Fin</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.propietario}</td>
                  <td>{item.ofertante}</td>
                  <td>{item.objeto}</td>
                  <td>{item.fechafin ? item.fechafin.toLocaleDateString() : 'N/A'}</td>
                  <td>{item.estado}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">No hay datos disponibles</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </Fragment>
  );
};

export default HistorialTrueques;
