// src/CirculosApoyo.js
import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, updateDoc, onSnapshot, query } from 'firebase/firestore';
import { db } from './firebase';
import './CirculosApoyo.css';

const CirculosApoyo = () => {
  const [circulos, setCirculos] = useState([]);
  const [newCircle, setNewCircle] = useState({ name: '', description: '', category: '', members: [], comments: [] });
  const [newMember, setNewMember] = useState('');
  const [newComment, setNewComment] = useState('');
  const [newCommentAuthor, setNewCommentAuthor] = useState('');
  const [selectedCircle, setSelectedCircle] = useState(null);

  useEffect(() => {
    fetchCircles();
  }, []);

  const fetchCircles = () => {
    const q = query(collection(db, 'circles'));
    onSnapshot(q, (querySnapshot) => {
      const circlesData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        members: doc.data().members || [],
        comments: doc.data().comments || [],
      }));
      setCirculos(circlesData);
    });
  };

  const handleAddCircle = async () => {
    if (newCircle.name.trim() === '') return;
    try {
      const docRef = await addDoc(collection(db, 'circles'), newCircle);
      setCirculos([...circulos, { ...newCircle, id: docRef.id }]);
      setNewCircle({ name: '', description: '', category: '', members: [], comments: [] });
    } catch (error) {
      console.error('Error adding circle:', error);
    }
  };

  const handleAddMember = async (circleId) => {
    if (newMember.trim() === '') return;
    const memberData = { name: newMember, role: 'member' };
    try {
      const circleDoc = doc(db, 'circles', circleId);
      const circleData = circulos.find(circle => circle.id === circleId);
      await updateDoc(circleDoc, {
        members: [...circleData.members, memberData]
      });
      const updatedCircles = circulos.map(circle => {
        if (circle.id === circleId) {
          return { ...circle, members: [...circle.members, memberData] };
        }
        return circle;
      });
      setCirculos(updatedCircles);
      setNewMember('');
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  const handleAddComment = async (circleId) => {
    if (newComment.trim() === '') return;
    const commentData = { author: newCommentAuthor || 'Anónimo', text: newComment, responses: [] };
    try {
      const circleDoc = doc(db, 'circles', circleId);
      const circleData = circulos.find(circle => circle.id === circleId);
      await updateDoc(circleDoc, {
        comments: [...circleData.comments, commentData]
      });
      const updatedCircles = circulos.map(circle => {
        if (circle.id === circleId) {
          return { ...circle, comments: [...circle.comments, commentData] };
        }
        return circle;
      });
      setCirculos(updatedCircles);
      setNewComment('');
      setNewCommentAuthor('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleAddResponse = async (circleId, commentIdx, response) => {
    const responseData = { text: response };
    try {
      const circleDoc = doc(db, 'circles', circleId);
      const circleData = circulos.find(circle => circle.id === circleId);
      circleData.comments[commentIdx].responses.push(responseData);
      await updateDoc(circleDoc, { comments: circleData.comments });
      const updatedCircles = circulos.map(circle => {
        if (circle.id === circleId) {
          return { ...circle, comments: circleData.comments };
        }
        return circle;
      });
      setCirculos(updatedCircles);
    } catch (error) {
      console.error('Error adding response:', error);
    }
  };

  return (
    <div className="circulos-apoyo-container">
      <h2>Círculos de Apoyo</h2>
      <div className="add-circle">
        <input 
          type="text" 
          placeholder="Nombre del círculo" 
          value={newCircle.name} 
          onChange={(e) => setNewCircle({ ...newCircle, name: e.target.value })} 
        />
        <input 
          type="text" 
          placeholder="Descripción" 
          value={newCircle.description} 
          onChange={(e) => setNewCircle({ ...newCircle, description: e.target.value })} 
        />
        <input 
          type="text" 
          placeholder="Categoría" 
          value={newCircle.category} 
          onChange={(e) => setNewCircle({ ...newCircle, category: e.target.value })} 
        />
        <button onClick={handleAddCircle}>Agregar Círculo</button>
      </div>
      <div className="circles-list">
        {circulos.map(circle => (
          <div key={circle.id} className="circle-item">
            <h3>{circle.name}</h3>
            <p>{circle.description}</p>
            <p><strong>Categoría:</strong> {circle.category}</p>
            <button onClick={() => setSelectedCircle(circle.id)}>Unirse al Círculo</button>
            <div className="members">
              <h4>Miembros:</h4>
              <ul>
                {circle.members.map((member, idx) => (
                  <li key={idx}>{member.name} ({member.role})</li>
                ))}
              </ul>
            </div>
            <div className="comments">
              <h4>Comentarios:</h4>
              {circle.comments.map((comment, index) => (
                <div key={index} className="comment-item">
                  <p><strong>{comment.author}</strong>: {comment.text}</p>
                  <div className="responses">
                    {comment.responses.map((response, idx) => (
                      <p key={idx} className="response">{response.text}</p>
                    ))}
                    <input 
                      type="text" 
                      placeholder={`Responder a ${comment.author}`} 
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddResponse(circle.id, index, e.target.value);
                          e.target.value = '';
                        }
                      }} 
                    />
                  </div>
                </div>
              ))}
            </div>
            {selectedCircle === circle.id && (
              <div className="add-comment">
                <input 
                  type="text" 
                  placeholder="Ingresa un comentario" 
                  value={newComment} 
                  onChange={(e) => setNewComment(e.target.value)} 
                />
                <input 
                  type="text" 
                  placeholder="Nombre comentarista (opcional)" 
                  value={newCommentAuthor} 
                  onChange={(e) => setNewCommentAuthor(e.target.value)} 
                />
                <button onClick={() => handleAddComment(circle.id)}>Agregar Comentario</button>
                <input 
                  type="text" 
                  placeholder="Ingresa tu nombre para unirte al círculo" 
                  value={newMember} 
                  onChange={(e) => setNewMember(e.target.value)} 
                />
                <button onClick={() => handleAddMember(circle.id)}>Unirse</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CirculosApoyo;
