// src/FavoresCRUD.js
import React, { useState, useEffect, Fragment } from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from './firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const FavoresCRUD = () => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setEditShow(false);
  };
  const handleShow = () => setShow(true);
  const handleEditShow = (favor) => {
    setEditDescripcion(favor.descripcion);
    setEditCategoria(favor.categoria);
    setEditEstado(favor.estado);
    setEditId(favor.id);
    setEditShow(true);
  };

  const [descripcion, setDescripcion] = useState('');
  const [categoria, setCategoria] = useState('');
  const [estado, setEstado] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [data, setData] = useState([]);
  
  const [editDescripcion, setEditDescripcion] = useState('');
  const [editCategoria, setEditCategoria] = useState('');
  const [editEstado, setEditEstado] = useState(0);
  const [editId, setEditId] = useState('');

  useEffect(() => {
    getCategorias();
    getFavores();
  }, []);

  const getCategorias = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'categorias'));
      const categoriasData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setCategorias(categoriasData);
    } catch (error) {
      console.error('Error fetching categories: ', error);
    }
  };

  const getFavores = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'favores'));
      const favoresData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setData(favoresData);
    } catch (error) {
      console.error('Error fetching favores: ', error);
    }
  };

  const handleGuardarFavor = async () => {
    const data = {
      descripcion,
      categoria,
      estado,
    };

    if (!descripcion || !categoria) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await addDoc(collection(db, 'favores'), data);
      getFavores();
      clear();
      handleClose();
      toast.success('Favor agregado con éxito');
    } catch (error) {
      console.error('Error adding favor: ', error);
      toast.error('Error al agregar favor');
    }
  };

  const handleUpdateFavor = async () => {
    const favorRef = doc(db, 'favores', editId);
    const data = {
      descripcion: editDescripcion,
      categoria: editCategoria,
      estado: editEstado,
    };

    if (!editDescripcion || !editCategoria) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await updateDoc(favorRef, data);
      getFavores();
      handleClose();
      toast.success('Favor actualizado con éxito');
    } catch (error) {
      console.error('Error updating favor: ', error);
      toast.error('Error al actualizar favor');
    }
  };

  const handleBorrarFavor = async (id) => {
    if (window.confirm("¿Deseas eliminar este favor?")) {
      try {
        await deleteDoc(doc(db, 'favores', id));
        setData(data.filter(item => item.id !== id));
        toast.success('Favor eliminado con éxito');
      } catch (error) {
        console.error('Error deleting favor: ', error);
        toast.error('Error al eliminar favor');
      }
    }
  };

  const clear = () => {
    setDescripcion('');
    setCategoria('');
    setEstado(0);
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            <Button variant="primary" onClick={handleShow}>Agregar Favor</Button>
          </Col>
        </Row>
        <br />

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Descripción</th>
              <th>Categoría</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.descripcion}</td>
                  <td>{item.categoria}</td>
                  <td>{item.estado === 1 ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <button className="btn btn-primary" onClick={() => handleEditShow(item)}>Editar</button>
                    &nbsp;
                    <button className="btn btn-danger" onClick={() => handleBorrarFavor(item.id)}>Eliminar</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">Loading...</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>

      {/* Modal para Agregar Favor */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Favor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese la descripción"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </Col>
            <Col>
              <select
                className="form-control"
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
              >
                <option value="">Seleccione Categoría</option>
                {categorias.map(categoria => (
                  <option key={categoria.id} value={categoria.nombre}>{categoria.nombre}</option>
                ))}
              </select>
            </Col>
            <Col>
              <input
                type="checkbox"
                checked={estado === 1}
                onChange={(e) => setEstado(e.target.checked ? 1 : 0)}
                value={estado}
              />
              <label>Activo</label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleGuardarFavor}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Editar Favor */}
      <Modal show={editShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Favor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese la descripción"
                value={editDescripcion}
                onChange={(e) => setEditDescripcion(e.target.value)}
              />
            </Col>
            <Col>
              <select
                className="form-control"
                value={editCategoria}
                onChange={(e) => setEditCategoria(e.target.value)}
              >
                <option value="">Seleccione Categoría</option>
                {categorias.map(categoria => (
                  <option key={categoria.id} value={categoria.nombre}>{categoria.nombre}</option>
                ))}
              </select>
            </Col>
            <Col>
            <input
                type="checkbox"
                checked={editEstado === 1}
                onChange={(e) => setEditEstado(e.target.checked ? 1 : 0)}
                value={editEstado}
              />
              <label>Activo</label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleUpdateFavor}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default FavoresCRUD;
