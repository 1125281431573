import React, { useState, useEffect, Fragment } from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from './firebase';
import { collection, getDocs, addDoc, query, where } from 'firebase/firestore';

const ModuloTrueques = () => {
  const [data, setData] = useState([]);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [offerDescription, setOfferDescription] = useState('');
  const [isLoan, setIsLoan] = useState(false);
  const [ownerName, setOwnerName] = useState('');

  useEffect(() => {
    getDatosTrueques();
  }, []);

  const getDatosTrueques = async () => {
    try {
      const truequesRef = collection(db, 'objetostrueque');
      const querySnapshot = await getDocs(truequesRef);
      const truequesData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: parseInt(doc.id, 10), // Convertir el ID a número
      }));
      setData(truequesData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const getOwnerName = async (idPropietario) => {
    try {
      const inspiradosRef = collection(db, 'inspirados');
      const q = query(inspiradosRef, where('cedula', '==', idPropietario));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const ownerData = querySnapshot.docs[0].data();
        return ownerData.nombre;
      }
    } catch (error) {
      console.error('Error fetching owner data: ', error);
    }
    return 'Desconocido';
  };

  const handleMakeOffer = async (item) => {
    const ownerName = await getOwnerName(item.idPropietario);
    setOwnerName(ownerName);
    setSelectedItem(item);
    setIsLoan(false); // Reset loan state
    setShowOfferModal(true);
  };

  const handleOfferSubmit = async () => {
    if (!offerDescription) {
      toast.error('Debe ingresar una descripción de la oferta');
      return;
    }

    try {
      const newOffer = {
        idObjeto: selectedItem.id, // Mantener el ID como número
        propietario: selectedItem.idPropietario,
        ofertante: localStorage.getItem('user'),
        objeto: selectedItem.nombre,
        estado: 'pendiente', // Establecer estado como pendiente
        descripcionOferta: offerDescription, // Guardar descripción de la oferta
        fechafin: new Date()
      };

      await addDoc(collection(db, 'historialtrueques'), newOffer);
      console.log("Oferta guardada exitosamente en Firestore:", newOffer); // Log para verificación
      setShowOfferModal(false);
      toast.success('Oferta realizada con éxito');
    } catch (error) {
      console.error('Error al realizar la oferta:', error);
      toast.error('Error al realizar la oferta');
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <h3>Lista de Trueques Disponibles</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Categoría</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.nombre}</td>
                  <td>{item.descripcion}</td>
                  <td>{item.categoria}</td>
                  <td>
                    <Button variant="primary" onClick={() => handleMakeOffer(item)}>Hacer Oferta</Button>
                    {item.categoria === 'Libro' && (
                      <>
                        &nbsp;
                        <Button variant="info" onClick={() => {
                          setIsLoan(true);
                          handleMakeOffer(item);
                        }}>Pedir prestado</Button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">Cargando...</td>
              </tr>
            )}
          </tbody>
        </Table>

        <Modal show={showOfferModal} onHide={() => setShowOfferModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Realizar Oferta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Propietario:</strong> {ownerName}</p>
            <input
              type="text"
              className="form-control"
              placeholder="Descripción de la oferta"
              value={offerDescription}
              onChange={(e) => setOfferDescription(e.target.value)}
            />
            {isLoan && <p>Esta oferta será considerada como un préstamo.</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowOfferModal(false)}>Cancelar</Button>
            <Button variant="primary" onClick={handleOfferSubmit}>Enviar Oferta</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default ModuloTrueques;
