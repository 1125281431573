// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Menu from './Menu';
import CRUD from './CRUD';
import FavoresCRUD from './FavoresCRUD';
import VerFavores from './VerFavores';
import Ejecutados from './Ejecutados';
import Recursos from './Recursos';
import CirculosApoyo from './CirculosApoyo';
import Inscribir from './Inscribir'; // Importa el nuevo componente Inscribir
import CuentaPersonal from './CuentaPersonal'; // Importa el nuevo componente CuentaPersonal
import './App.css';
import styled from 'styled-components';
import HistorialTrueques from './HistorialTrueques';
import Trueques from './Trueques';
import ModuloTrueques from './ModuloTrueques';


const AppContainer = styled.div`
  text-align: center;
  background-color: #f7f9fc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
`;

const Header = styled.header`
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
`;

const HeaderWrapper = ({ children }) => {
  const location = useLocation();

  // Check if the current path is "/crud" or "/ver-favores" and update the header accordingly
  if (location.pathname === '/crud') {
    return (
      <Header>
        <h1>Miembros Eco Inspira</h1>
        <p>Esta es la pantalla para adicionar inspirados a la comunidad solidaria Eco Inspira.</p>
      </Header>
    );
  }

  if (location.pathname === '/ver-favores') {
    return (
      <Header>
        <h1>Gestión de Favores</h1>
        <p>Esta es la pantalla para gestionar favores y asociarlos a los inspirados.</p>
      </Header>
    );
  }

  return location.pathname !== '/' ? <Header>{children}</Header> : null;
};

function App() {
  useEffect(() => {
    console.log('Firebase Firestore está listo para usar');
  }, []);

  return (
    <Router>
      <AppContainer>
        <HeaderWrapper>
          <h1>Bienvenido a Eco Inspira</h1>
        </HeaderWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/crud" element={<CRUD />} />
          <Route path="/favores-crud" element={<FavoresCRUD />} />
          <Route path="/ver-favores" element={<VerFavores />} />
          <Route path="/ejecutados" element={<Ejecutados />} />
          <Route path="/recursos" element={<Recursos />} />
          <Route path="/circulosapoyo" element={<CirculosApoyo />} />
          <Route path="/inscribir" element={<Inscribir />} />
          <Route path="/cuenta-personal" element={<CuentaPersonal />} /> {/* Añade la nueva ruta para CuentaPersonal */}
          <Route path="/trueques" element={<Trueques />} />
          <Route path="/historialtrueques" element={<HistorialTrueques />} />
          <Route path="/modulottrueques" element={<ModuloTrueques />} />
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
