import React, { useState, useEffect, Fragment } from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from './firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

// Componente para gestionar los favores
const FavoresCRUD = ({ categorias, getDatosFavores, data, handleGuardarFavor }) => {
  const [descripcion, setDescripcion] = useState('');
  const [categoria, setCategoria] = useState('');
  const [estado, setEstado] = useState(0);
  const [newCategoria, setNewCategoria] = useState(false);
  const [newCategoriaNombre, setNewCategoriaNombre] = useState('');

  const clear = () => {
    setDescripcion('');
    setCategoria('');
    setEstado(0);
    setNewCategoria(false);
    setNewCategoriaNombre('');
  };

  return (
    <Container>
      <Row>
        <Col>
          <input
            type="text"
            className="form-control"
            placeholder="Ingrese la descripción del favor"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Col>
        <Col>
          <select
            className="form-control"
            value={categoria}
            onChange={(e) => {
              if (e.target.value === 'Nueva categoría') {
                setNewCategoria(true);
                setCategoria(e.target.value);
              } else {
                setNewCategoria(false);
                setCategoria(e.target.value);
              }
            }}
          >
            <option value="">Seleccione una categoría</option>
            {categorias.map((cat) => (
              <option key={cat.id} value={cat.descripcion}>{cat.descripcion}</option>
            ))}
            <option value="Nueva categoría">Nueva categoría</option>
          </select>
          {newCategoria && (
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Ingrese el nombre de la nueva categoría"
              value={newCategoriaNombre}
              onChange={(e) => setNewCategoriaNombre(e.target.value)}
            />
          )}
        </Col>
        <Col>
          <input
            type="checkbox"
            checked={estado === 1}
            onChange={(e) => setEstado(e.target.checked ? 1 : 0)}
            value={estado}
          />
          <label>Activo</label>
        </Col>
        <Col>
          <button className="btn btn-primary" onClick={() => handleGuardarFavor(descripcion, categoria, estado, newCategoria, newCategoriaNombre, clear)}>
            Guardar
          </button>
        </Col>
      </Row>
    </Container>
  );
};

// Componente para gestionar la relación de favores e inspirados
const RelacionFavoresInspirados = ({ inspirados, handleSeleccionarInspirado, selectedInspirado, favoresRelacionados, handleBorrarFavorRelacionado }) => {
  return (
    <Row>
      <Col>
        <h3>Inspirados</h3>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {inspirados.map((item) => (
            <Button key={item.id} variant="info" onClick={() => handleSeleccionarInspirado(item)} className="mb-2">
              {item.nombre}
            </Button>
          ))}
        </div>
      </Col>
      <Col>
        {selectedInspirado && (
          <div>
            <h4>Favores relacionados con {selectedInspirado.nombre}</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Descripción</th>
                  <th>Categoría</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {favoresRelacionados && favoresRelacionados.length > 0 ? (
                  favoresRelacionados.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.descripcion}</td>
                      <td>{item.categoria}</td>
                      <td>
                        <button className="btn btn-danger" onClick={() => handleBorrarFavorRelacionado(selectedInspirado.cedula, item.id)}>Eliminar</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">No hay favores relacionados</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </Col>
    </Row>
  );
};

// Componente principal
const VerFavores = () => {
  const [show, setShow] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedFavor, setSelectedFavor] = useState(null);
  const [selectedInspiradoCedula, setSelectedInspiradoCedula] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editDescripcion, setEditDescripcion] = useState('');
  const [editCategoria, setEditCategoria] = useState('');
  const [editEstado, setEditEstado] = useState(0);
  const [editId, setEditId] = useState('');

  const [data, setData] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [inspirados, setInspirados] = useState([]);
  const [selectedInspirado, setSelectedInspirado] = useState(null);
  const [favoresRelacionados, setFavoresRelacionados] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getDatosFavores();
    getCategorias();
    getInspirados();
  }, []);

  const getDatosFavores = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'favores'));
      const favoresData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setData(favoresData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const getCategorias = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'categorias'));
      const categoriasData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setCategorias(categoriasData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const getInspirados = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'inspirados'));
      const inspiradosData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setInspirados(inspiradosData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleEditar = (favor) => {
    handleShow();
    setEditDescripcion(favor.descripcion);
    setEditCategoria(favor.categoria);
    setEditEstado(favor.estado);
    setEditId(favor.id);
  };

  const handleBorrar = async (id) => {
    if (window.confirm("¿Deseas eliminar este favor?")) {
      try {
        await deleteDoc(doc(db, 'favores', id));
        setData(data.filter(item => item.id !== id));
        toast.success('Favor eliminado con éxito');
      } catch (error) {
        console.error('Error deleting document: ', error);
        toast.error('Error al eliminar favor');
      }
    }
  };

  const handleBorrarFavorRelacionado = async (cedula, idFavor) => {
    if (window.confirm("¿Deseas eliminar este favor relacionado?")) {
      try {
        const favorDocRef = doc(db, `inspirados/${cedula}/favores`, idFavor);
        await deleteDoc(favorDocRef);
        setFavoresRelacionados(favoresRelacionados.filter(item => item.id !== idFavor));
        toast.success('Favor relacionado eliminado con éxito');
      } catch (error) {
        console.error('Error deleting document: ', error);
        toast.error('Error al eliminar favor relacionado');
      }
    }
  };

  const handleUpdate = async () => {
    const favorRef = doc(db, 'favores', editId);
    const data = {
      descripcion: editDescripcion,
      categoria: editCategoria,
      estado: editEstado
    };

    if (!editDescripcion || !editCategoria) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await updateDoc(favorRef, data);
      getDatosFavores();
      handleClose();
      toast.success('Favor actualizado con éxito');
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error al actualizar favor');
    }
  };

  const handleGuardarFavor = async (descripcion, categoria, estado, newCategoria, newCategoriaNombre, clear) => {
    let categoriaId = categoria;
    if (categoria === 'Nueva categoría') {
      const newCategoriaData = {
        descripcion: newCategoriaNombre,
        estado: 1
      };
      try {
        await addDoc(collection(db, 'categorias'), newCategoriaData);
        categoriaId = newCategoriaData.descripcion;
        await getCategorias(); // Update categories list
      } catch (error) {
        console.error('Error adding document: ', error);
        toast.error('Error al agregar nueva categoría');
        return;
      }
    }

    const newFavorData = {
      descripcion,
      categoria: categoriaId,
      estado: 1
    };

    if (!descripcion || !categoria) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await addDoc(collection(db, 'favores'), newFavorData);
      await getDatosFavores();
      clear();
      toast.success('Favor agregado con éxito');
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error('Error al agregar favor');
    }
  };

  const handleSeleccionarInspirado = async (inspirado) => {
    setSelectedInspirado(inspirado);
    getFavoresRelacionados(inspirado.cedula);
  };

  const getFavoresRelacionados = async (cedula) => {
    try {
      const querySnapshot = await getDocs(collection(db, `inspirados/${cedula}/favores`));
      const favoresRelacionadosData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setFavoresRelacionados(favoresRelacionadosData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleRelacionarFavor = (favor) => {
    setSelectedFavor(favor);
    setShowAssignModal(true);
  };

  const handleAssignFavor = async () => {
    if (!selectedInspiradoCedula) {
      toast.error('Seleccione un inspirado para asignar el favor');
      return;
    }

    const favorRef = doc(db, 'favores', selectedFavor.id);
    const favorDoc = await getDoc(favorRef);
    const favorData = favorDoc.data();

    try {
      await addDoc(collection(db, `inspirados/${selectedInspiradoCedula}/favores`), {
        descripcion: favorData.descripcion,
        categoria: favorData.categoria,
        estado: favorData.estado
      });
      toast.success('Favor asignado con éxito');
      setShowAssignModal(false);
    } catch (error) {
      console.error('Error assigning favor: ', error);
      toast.error('Error al asignar favor');
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <FavoresCRUD categorias={categorias} getDatosFavores={getDatosFavores} data={data} handleGuardarFavor={handleGuardarFavor} />
      <br />
      <Row>
        <Col md={6}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Descripción</th>
                <th>Categoría</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.descripcion}</td>
                    <td>{item.categoria}</td>
                    <td colSpan={3}>
                      <button className="btn btn-primary" onClick={() => handleEditar(item)}>Editar</button>
                      &nbsp;
                      <button className="btn btn-danger" onClick={() => handleBorrar(item.id)}>Eliminar</button>
                      &nbsp;
                      <button className="btn btn-info" onClick={() => handleRelacionarFavor(item)}>Inspirado</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">Loading...</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col md={6}>
          <RelacionFavoresInspirados
            inspirados={inspirados}
            handleSeleccionarInspirado={handleSeleccionarInspirado}
            selectedInspirado={selectedInspirado}
            favoresRelacionados={favoresRelacionados}
            handleBorrarFavorRelacionado={handleBorrarFavorRelacionado}
          />
        </Col>
      </Row>
      <Button variant="secondary" onClick={() => navigate('/crud')}>
        Volver
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar información del favor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese la descripción"
                value={editDescripcion}
                onChange={(e) => setEditDescripcion(e.target.value)}
              />
            </Col>
            <Col>
              <select
                className="form-control"
                value={editCategoria}
                onChange={(e) => setEditCategoria(e.target.value)}
              >
                <option value="">Seleccione una categoría</option>
                {categorias.map((cat) => (
                  <option key={cat.id} value={cat.descripcion}>{cat.descripcion}</option>
                ))}
              </select>
            </Col>
            <Col>
              <input
                type="checkbox"
                checked={editEstado === 1}
                onChange={(e) => setEditEstado(e.target.checked ? 1 : 0)}
                value={editEstado}
              />
              <label>Activo</label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAssignModal} onHide={() => setShowAssignModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Asignar Favor a Inspirado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <select
                className="form-control"
                value={selectedInspiradoCedula}
                onChange={(e) => setSelectedInspiradoCedula(e.target.value)}
              >
                <option value="">Seleccione un inspirado</option>
                {inspirados.map((inspirado) => (
                  <option key={inspirado.id} value={inspirado.cedula}>{inspirado.nombre}</option>
                ))}
              </select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAssignModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAssignFavor}>
            Asignar
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default VerFavores;
