// src/Ejecutados.js
import React, { useState, useEffect, Fragment } from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from './firebase';
import { collection, getDocs, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Ejecutados = () => {
  const [inspirado, setInspirado] = useState('');
  const [favor, setFavor] = useState('');
  const [beneficiado, setBeneficiado] = useState('');
  const [ejecutados, setEjecutados] = useState([]);
  const [inspirados, setInspirados] = useState([]);
  const [favores, setFavores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDatosInspirados();
    getDatosFavores();
  }, []);

  const getDatosInspirados = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'inspirados'));
      const inspiradosData = querySnapshot.docs.map(doc => doc.data().nombre);
      console.log('Inspirados data:', inspiradosData); // Debug log
      setInspirados(inspiradosData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const getDatosFavores = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'favores'));
      const favoresData = querySnapshot.docs.map(doc => doc.data().descripcion);
      console.log('Favores data:', favoresData); // Debug log
      setFavores(favoresData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleGuardarEjecutado = async () => {
    if (!inspirado || !favor || !beneficiado) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      const inspiradoDoc = await getDocs(collection(db, 'inspirados'));
      const inspiradoData = inspiradoDoc.docs.find(doc => doc.data().nombre === inspirado);

      if (!inspiradoData) {
        toast.error('Inspirado no encontrado');
        return;
      }

      const ejecutadoData = {
        inspirado,
        favor,
        beneficiado
      };

      console.log('Adding ejecutado data:', ejecutadoData); // Debug log
      await addDoc(collection(db, `inspirados/${inspiradoData.id}/ejecutados`), ejecutadoData);

      const inspiradoRef = doc(db, 'inspirados', inspiradoData.id);
      const inspiradoSnap = await getDoc(inspiradoRef);  // Obtener el documento del inspirado
      const inspiradoScore = inspiradoSnap.data().score || 0;  // Obtener el score actual

      await updateDoc(inspiradoRef, {
        score: inspiradoScore + 1  // Incrementar el score
      });

      getEjecutados();
      toast.success('Ejecutado agregado con éxito');
    } catch (error) {
      console.error('Error adding ejecutado: ', error);
      toast.error('Error al agregar ejecutado');
    }
  };

  const getEjecutados = async () => {
    try {
      const ejecutadosList = [];
      const querySnapshot = await getDocs(collection(db, 'inspirados'));
      for (const doc of querySnapshot.docs) {
        const subCollection = await getDocs(collection(db, `inspirados/${doc.id}/ejecutados`));
        subCollection.forEach(subDoc => {
          ejecutadosList.push({ ...subDoc.data(), id: subDoc.id });
        });
      }
      console.log('Fetched ejecutados:', ejecutadosList); // Debug log
      setEjecutados(ejecutadosList);
    } catch (error) {
      console.error('Error fetching ejecutados: ', error);
    }
  };

  useEffect(() => {
    getEjecutados();
  }, []);

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <h2>Gestionar Ejecutados</h2>
        <Row>
          <Col>
            <label>Inspirado</label>
            <select
              className="form-control"
              value={inspirado}
              onChange={(e) => setInspirado(e.target.value)}
            >
              <option value="">Seleccione un inspirado</option>
              {inspirados.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </Col>
          <Col>
            <label>Favor</label>
            <select
              className="form-control"
              value={favor}
              onChange={(e) => setFavor(e.target.value)}
            >
              <option value="">Seleccione un favor</option>
              {favores.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </Col>
          <Col>
            <label>Beneficiado</label>
            <select
              className="form-control"
              value={beneficiado}
              onChange={(e) => setBeneficiado(e.target.value)}
            >
              <option value="">Seleccione un beneficiado</option>
              {inspirados.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Button variant="primary" onClick={handleGuardarEjecutado}>Guardar</Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Button variant="secondary" onClick={() => navigate('/crud')}>Volver</Button>
          </Col>
        </Row>
        <h3 className="mt-5">Historial de Ejecutados</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Inspirado</th>
              <th>Favor</th>
              <th>Beneficiado</th>
            </tr>
          </thead>
          <tbody>
            {ejecutados.length > 0 ? (
              ejecutados.map((item, index) => (
                <tr key={index}>
                  <td>{item.inspirado}</td>
                  <td>{item.favor}</td>
                  <td>{item.beneficiado}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">No hay ejecutados</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </Fragment>
  );
};

export default Ejecutados;
