// src/CuentaPersonal.js
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from './firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import './CuentaPersonal.css';

const CuentaPersonal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};

  const [inspirado, setInspirado] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [nombre, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [usuario, setUsuario] = useState('');
  const [estado, setEstado] = useState(0);

  useEffect(() => {
    if (!userId) {
      toast.error('No se encontró el inspirado');
      return;
    }

    const fetchData = async () => {
      const docRef = doc(db, 'inspirados', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setInspirado(data);
        setNombre(data.nombre);
        setCedula(data.cedula);
        setTelefono(data.telefono);
        setCorreo(data.correo);
        setUsuario(data.usuario);
        setEstado(data.estado);
      } else {
        toast.error('No se encontró el inspirado');
      }
    };

    fetchData();
  }, [userId]);

  const handleUpdate = async () => {
    const inspiradoRef = doc(db, 'inspirados', cedula);
    const updatedData = {
      nombre,
      cedula,
      telefono,
      correo,
      usuario,
      estado
    };

    if (!nombre || !cedula || !telefono || !correo || !usuario) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await updateDoc(inspiradoRef, updatedData);
      setInspirado(updatedData);
      setEditMode(false);
      toast.success('Datos actualizados con éxito');
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error al actualizar los datos');
    }
  };

  const generateCardNumber = () => {
    let cardNumber = '';
    for (let i = 0; i < 4; i++) {
      cardNumber += Math.floor(1000 + Math.random() * 9000).toString();
      if (i < 3) cardNumber += ' ';
    }
    return cardNumber;
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container className="card-container">
        {inspirado && (
          <>
            <div className="card">
              <div className="card-title">CEIBANK</div>
              <div className="card-number">{generateCardNumber()}</div>
              <div className="card-member-since">Miembro desde: {inspirado.fechaInscripcion}</div>
              <div className="card-name">{inspirado.nombre}</div>
              <div className="card-buttons">
                <Button variant="outline-light" onClick={() => navigate('/trueques')}>Trueques</Button>
                <Button variant="outline-light" onClick={() => navigate('/circulosapoyo')}>Ideas</Button>
                <Button variant="outline-light" onClick={() => navigate('/contribuciones')}>Contribuciones</Button>
              </div>
              <div className="card-ecoin">Ecoin$: {inspirado.score}</div>
            </div>
            <Button variant="primary" onClick={() => setEditMode(!editMode)}>Editar</Button>
          </>
        )}
        {editMode && (
          <Container>
            <Row>
              <Col md={6}>
                <label>Nombre:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Escribe tu nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Col>
              <Col md={6}>
                <label>Documento:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Escribe tu documento"
                  value={cedula}
                  onChange={(e) => setCedula(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label>Teléfono:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Escribe tu teléfono"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                />
              </Col>
              <Col md={6}>
                <label>Correo:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Escribe tu correo"
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label>Usuario:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Escribe tu usuario"
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                />
              </Col>
              <Col md={6}>
                <label>Activo:</label>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={estado === 1}
                    onChange={(e) => setEstado(e.target.checked ? 1 : 0)}
                    value={estado}
                  />
                  <label className="form-check-label">Sí</label>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button variant="primary" onClick={handleUpdate}>Guardar</Button>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
    </Fragment>
  );
};

export default CuentaPersonal;
