import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from './firebase';
import { collection, getDocs, setDoc, updateDoc, deleteDoc, doc, query, where } from 'firebase/firestore';

const Trueques = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showOffersModal, setShowOffersModal] = useState(false);
  const [selectedItemOffers, setSelectedItemOffers] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [categoria, setCategoria] = useState('A');
  const [idObjeto, setIdObjeto] = useState(0);

  const [editNombre, setEditNombre] = useState('');
  const [editDescripcion, setEditDescripcion] = useState('');
  const [editCategoria, setEditCategoria] = useState('A');
  const [editIdObjeto, setEditIdObjeto] = useState('');

  const [data, setData] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      getDatosTrueques();
    }
  }, [userData]);

  const getUserData = async () => {
    try {
      const user = localStorage.getItem('user');
      if (user) {
        const querySnapshot = await getDocs(collection(db, 'inspirados'));
        const userDoc = querySnapshot.docs.find(doc => doc.data().user === user);
        if (userDoc) {
          setUserData(userDoc.data());
        } else {
          toast.error('Usuario no encontrado en la colección inspirados');
        }
      } else {
        toast.error('No hay usuario en sesión');
      }
    } catch (error) {
      console.error('Error fetching user data: ', error);
      toast.error('Error al obtener datos del usuario');
    }
  };

  const getDatosTrueques = async () => {
    try {
      const truequesRef = collection(db, 'objetostrueque');
      const q = query(truequesRef, where('idPropietario', '==', userData.cedula));
      const querySnapshot = await getDocs(q);

      const truequesData = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const objetoData = { ...doc.data(), id: doc.id, desactivado: false };

          // Verificar si el objeto ya ha sido truequeado
          const historialRef = collection(db, 'historialtrueques');
          const historialSnapshot = await getDocs(query(historialRef, where('idObjeto', '==', objetoData.idObjeto)));
          const isTruequeado = historialSnapshot.docs.some(doc => doc.data().estado === 'truequeado');

          if (isTruequeado) {
            objetoData.desactivado = true;
          }

          return objetoData;
        })
      );

      setData(truequesData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const getNextIdObjeto = async () => {
    try {
      const truequesRef = collection(db, 'objetostrueque');
      const querySnapshot = await getDocs(truequesRef);
      const allObjects = querySnapshot.docs.map(doc => doc.data().idObjeto);
      const maxIdObjeto = allObjects.length > 0 ? Math.max(...allObjects) : 0;
      return maxIdObjeto + 1;
    } catch (error) {
      console.error('Error fetching idObjeto: ', error);
      toast.error('Error al obtener el próximo idObjeto');
      return 1;
    }
  };

  const handleGuardarTrueque = async () => {
    if (!userData) {
      toast.error('Error al obtener datos del usuario');
      return;
    }

    const newIdObjeto = await getNextIdObjeto();

    const newData = {
      idObjeto: newIdObjeto,
      idPropietario: userData.cedula,
      nombre,
      descripcion,
      categoria
    };

    if (!nombre || !descripcion || !categoria) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await setDoc(doc(db, 'objetostrueque', newIdObjeto.toString()), newData);
      getDatosTrueques();
      clearForm();
      toast.success('Objeto de trueque agregado con éxito');
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error('Error al agregar objeto de trueque');
    }
  };

  const handleEditar = (trueque) => {
    setShowEditModal(true);
    setEditNombre(trueque.nombre);
    setEditDescripcion(trueque.descripcion);
    setEditCategoria(trueque.categoria);
    setEditIdObjeto(trueque.idObjeto);
  };

  const handleVerOfertas = async (trueque) => {
    try {
      const historialRef = collection(db, 'historialtrueques');
      const q = query(historialRef, where('idObjeto', '==', trueque.idObjeto)); // Filtrar por idObjeto específico
      const querySnapshot = await getDocs(q);
      const offersData = querySnapshot.docs.map(doc => doc.data());
      setSelectedItemOffers(offersData);
      setSelectedItem(trueque);
      setShowOffersModal(true);
    } catch (error) {
      console.error('Error fetching offers: ', error);
      toast.error('Error al obtener las ofertas');
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleCloseOffersModal = () => {
    setShowOffersModal(false);
  };

  const handleUpdate = async () => {
    if (!userData) {
      toast.error('Error al obtener datos del usuario');
      return;
    }

    const truequeRef = doc(db, 'objetostrueque', editIdObjeto.toString());
    const updatedData = {
      idObjeto: editIdObjeto,
      idPropietario: userData.cedula,
      nombre: editNombre,
      descripcion: editDescripcion,
      categoria: editCategoria
    };

    if (!editNombre || !editDescripcion || !editCategoria) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await updateDoc(truequeRef, updatedData);
      getDatosTrueques();
      setShowEditModal(false);
      toast.success('Objeto de trueque actualizado con éxito');
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error al actualizar objeto de trueque');
    }
  };

  const handleAceptarOferta = async (oferta) => {
    try {
      const historialRef = collection(db, 'historialtrueques');
      const querySnapshot = await getDocs(historialRef);
      const docRef = querySnapshot.docs.find(
        doc =>
          doc.data().idObjeto === selectedItem.idObjeto && // Filtrar por idObjeto
          doc.data().ofertante === oferta.ofertante &&
          doc.data().objeto === oferta.objeto
      );
      if (docRef) {
        await updateDoc(docRef.ref, {
          estado: 'truequeado',
          fechafin: new Date()
        });
        toast.success('Oferta aceptada con éxito');
        handleVerOfertas(selectedItem); // Refresh offers list
        desactivarEdicionYCancelacion(selectedItem);
      } else {
        toast.error('No se encontró el registro en historialtrueques');
      }
    } catch (error) {
      console.error('Error al aceptar oferta: ', error);
      toast.error('Error al aceptar oferta');
    }
  };

  const handleRechazarOferta = async (oferta) => {
    try {
      const historialRef = collection(db, 'historialtrueques');
      const querySnapshot = await getDocs(historialRef);
      const docRef = querySnapshot.docs.find(
        doc =>
          doc.data().idObjeto === selectedItem.idObjeto && // Filtrar por idObjeto
          doc.data().ofertante === oferta.ofertante &&
          doc.data().objeto === oferta.objeto
      );
      if (docRef) {
        await updateDoc(docRef.ref, {
          estado: 'rechazada',
          fechafin: new Date()
        });
        toast.success('Oferta rechazada con éxito');
        handleVerOfertas(selectedItem); // Refresh offers list
      } else {
        toast.error('No se encontró el registro en historialtrueques');
      }
    } catch (error) {
      console.error('Error al rechazar oferta: ', error);
      toast.error('Error al rechazar oferta');
    }
  };

  const handleCancelarTrueque = async (idObjeto) => {
    try {
      await deleteDoc(doc(db, 'objetostrueque', idObjeto.toString()));
      getDatosTrueques();
      toast.success('Objeto de trueque cancelado con éxito');
    } catch (error) {
      console.error('Error al cancelar trueque: ', error);
      toast.error('Error al cancelar trueque');
    }
  };

  const desactivarEdicionYCancelacion = (item) => {
    const updatedData = data.map((obj) =>
      obj.id === item.id ? { ...obj, desactivado: true } : obj
    );
    setData(updatedData);
  };

  const clearForm = () => {
    setNombre('');
    setDescripcion('');
    setCategoria('A');
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <Row className="mb-3">
          <Col>
            <Button variant="danger" onClick={() => {
              localStorage.removeItem('user');
              navigate('/');
            }}>Cerrar Sesión</Button>
          </Col>
          <Col>
            <Button variant="secondary" onClick={() => navigate('/historialtrueques')}>Historial Trueques</Button>
          </Col>
          <Col>
            <Button variant="success" onClick={() => navigate('/modulottrueques')}>Ir a Modulo Trueques</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="text"
              className="form-control"
              placeholder="Ingrese el nombre del objeto"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Col>
          <Col>
            <input
              type="text"
              className="form-control"
              placeholder="Ingrese la descripción"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </Col>
          <Col>
            <select
              className="form-control"
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
            >
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="Libro">Libro</option>
            </select>
          </Col>
          <Col>
            <button className="btn btn-primary" onClick={handleGuardarTrueque}>Guardar</button>
          </Col>
        </Row>
        <br />
        <h3>Lista de objetos de trueque</h3>
        <Table striped bordered hover className="CRUD-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Categoría</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.nombre}</td>
                  <td>{item.descripcion}</td>
                  <td>{item.categoria}</td>
                  <td>
                    <button className="btn btn-primary" onClick={() => handleEditar(item)} disabled={item.desactivado}>Editar</button>
                    &nbsp;
                    <button className="btn btn-danger" onClick={() => handleCancelarTrueque(item.idObjeto)} disabled={item.desactivado}>Cancelar Trueque</button>
                    &nbsp;
                    <button className="btn btn-info" onClick={() => handleVerOfertas(item)}>Ver Ofertas</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">Cargando...</td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Modal para Editar Trueques */}
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Actualizar objeto de trueque</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese el nombre del objeto"
                  value={editNombre}
                  onChange={(e) => setEditNombre(e.target.value)}
                />
              </Col>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese la descripción"
                  value={editDescripcion}
                  onChange={(e) => setEditDescripcion(e.target.value)}
                />
              </Col>
              <Col>
                <select
                  className="form-control"
                  value={editCategoria}
                  onChange={(e) => setEditCategoria(e.target.value)}
                >
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="Libro">Libro</option>
                </select>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Cancela
            </Button>
            <Button variant="primary" onClick={handleUpdate}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal para Ver Ofertas */}
        <Modal show={showOffersModal} onHide={handleCloseOffersModal}>
          <Modal.Header closeButton>
            <Modal.Title>Ofertas Recibidas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItemOffers.length > 0 ? (
              <Table striped bordered hover className="CRUD-table">
                <thead>
                  <tr>
                    <th>Ofertante</th>
                    <th>Descripción</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItemOffers.map((offer, index) => (
                    <tr key={index}>
                      <td>{offer.ofertante}</td>
                      <td>{offer.descripcionOferta || 'Sin descripción'}</td> {/* Mostrar descripción de la oferta */}
                      <td>{offer.estado}</td>
                      <td>
                        {offer.estado === 'pendiente' && (
                          <>
                            <Button variant="success" onClick={() => handleAceptarOferta(offer)}>Aceptar</Button>
                            &nbsp;
                            <Button variant="danger" onClick={() => handleRechazarOferta(offer)}>Rechazar</Button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No hay ofertas para este objeto.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseOffersModal}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default Trueques;
