// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics, isSupported } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAP1qwDcsvt1HXVCJoMsdlL4C8TDnfQiKY",
  authDomain: "solbank2-46e99.firebaseapp.com",
  databaseURL: "https://solbank2-46e99-default-rtdb.firebaseio.com",
  projectId: "solbank2-46e99",
  storageBucket: "solbank2-46e99.appspot.com",
  messagingSenderId: "576442025663",
  appId: "1:576442025663:web:f763663edc57540dba680a"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Initialize analytics if supported
let analytics;
isSupported().then((supported) => {
  if (supported) {
    analytics = getAnalytics(app);
  }
}).catch(error => {
  console.error("Analytics not supported", error);
});

export { db, auth, storage, analytics };
