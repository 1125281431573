// src/CRUD.js
import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db, auth } from './firebase';
import { collection, getDocs, setDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';

const CRUD = () => {
  const [showPasswordModal, setShowPasswordModal] = useState(true);
  const [password, setPassword] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();

  const [nombre, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [user, setUser] = useState('');
  const [estado, setEstado] = useState(0);

  const [editNombre, setEditNombre] = useState('');
  const [editCedula, setEditCedula] = useState('');
  const [editTelefono, setEditTelefono] = useState('');
  const [editCorreo, setEditCorreo] = useState('');
  const [editUser, setEditUser] = useState('');
  const [editEstado, setEditEstado] = useState(0);
  const [editId, setEditId] = useState('');

  const [data, setData] = useState([]);

  useEffect(() => {
    if (accessGranted) {
      getDatosIns();
    }
  }, [accessGranted]);

  const getDatosIns = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'inspirados'));
      const inspiradosData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setData(inspiradosData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handlePasswordSubmit = () => {
    if (password === 'omega3') {
      setAccessGranted(true);
      setShowPasswordModal(false);
    } else {
      toast.error('Contraseña incorrecta');
    }
  };

  const handleEditar = (inspirado) => {
    setShowEditModal(true);
    setEditNombre(inspirado.nombre);
    setEditCedula(inspirado.cedula);
    setEditTelefono(inspirado.telefono);
    setEditCorreo(inspirado.correo);
    setEditUser(inspirado.user);
    setEditEstado(inspirado.estado);
    setEditId(inspirado.id);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleBorrar = async (id) => {
    if (window.confirm("¿Deseas eliminar a este inspirado?")) {
      try {
        await deleteDoc(doc(db, 'inspirados', id));
        setData(data.filter(item => item.id !== id));
        toast.success('Inspirado eliminado con éxito');
      } catch (error) {
        console.error('Error deleting document: ', error);
        toast.error('Error al eliminar inspirado');
      }
    }
  };

  const handleUpdate = async () => {
    const inspiradoRef = doc(db, 'inspirados', editId);
    const data = {
      nombre: editNombre,
      cedula: editCedula,
      telefono: editTelefono,
      correo: editCorreo,
      user: editUser,
      estado: editEstado
    };

    if (!editNombre || !editCedula || !editTelefono || !editCorreo || !editUser) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await updateDoc(inspiradoRef, data);
      getDatosIns();
      setShowEditModal(false);
      toast.success('Inspirado actualizado con éxito');
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error al actualizar inspirado');
    }
  };

  const handleGuardarIns = async () => {
    const fechaInscripcion = new Date().toLocaleDateString('es-ES');
    const data = {
      nombre: nombre,
      cedula: cedula,
      telefono: telefono,
      correo: correo,
      user: user,
      estado: estado,
      fechaInscripcion: fechaInscripcion,
      score: 3 // Agregar el campo score con valor inicial 3
    };

    if (!nombre || !cedula || !telefono || !correo || !user) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      // Usar `setDoc` en lugar de `addDoc` y usar `cedula` como ID
      await setDoc(doc(db, 'inspirados', cedula), data);

      getDatosIns();
      clear();
      toast.success('Inspirado agregado con éxito');
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error('Error al agregar inspirado');
    }
  };

  const clear = () => {
    setNombre('');
    setCedula('');
    setTelefono('');
    setCorreo('');
    setUser('');
    setEstado(0);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirigir a la página de inicio después de cerrar sesión
    } catch (error) {
      console.error('Error signing out: ', error);
      toast.error('Error al cerrar sesión');
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      {!accessGranted && (
        <Modal show={!accessGranted} onHide={() => setShowPasswordModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Autenticación requerida</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="password"
              className="form-control"
              placeholder="Ingrese la contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handlePasswordSubmit}>
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {accessGranted && (
        <Container>
          <Row className="mb-3">
            <Col>
              <Button variant="danger" onClick={handleSignOut}>Cerrar Sesión</Button>
            </Col>
            <Col>
              <Button variant="info" onClick={() => navigate('/ver-favores')}>Ver Favores</Button>
            </Col>
            <Col>
              <Button variant="secondary" onClick={() => navigate('/ejecutados')}>Ejecutados</Button> {/* Nuevo botón */}
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese el nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Col>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese el documento"
                value={cedula}
                onChange={(e) => setCedula(e.target.value)}
              />
            </Col>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese el teléfono"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </Col>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese el correo"
                value={correo}
                onChange={(e) => setCorreo(e.target.value)}
              />
            </Col>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese el usuario"
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
            </Col>
            <Col>
              <input
                type="checkbox"
                checked={estado === 1}
                onChange={(e) => setEstado(e.target.checked ? 1 : 0)}
                value={estado}
              />
              <label>Activo</label>
            </Col>
            <Col>
              <button className="btn btn-primary" onClick={handleGuardarIns}>Guardar</button>
            </Col>
          </Row>
          <br />
          <h3>Lista de inspirados</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Cédula</th>
                <th>Teléfono</th>
                <th>Correo</th>
                <th>Usuario</th> {/* Nueva columna para usuario */}
                <th>Estado</th>
                <th>Score</th> {/* Agregar encabezado de la columna Score */}
                <th>Fecha de Inscripción</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.nombre}</td>
                    <td>{item.cedula}</td>
                    <td>{item.telefono}</td>
                    <td>{item.correo}</td>
                    <td>{item.user}</td> {/* Mostrar el campo usuario */}
                    <td>{item.estado}</td>
                    <td>{item.score}</td> {/* Mostrar el campo Score */}
                    <td>{item.fechaInscripcion}</td>
                    <td colSpan={2}>
                      <button className="btn btn-primary" onClick={() => handleEditar(item)}>Editar</button>
                      &nbsp;
                      <button className="btn btn-danger" onClick={() => handleBorrar(item.id)}>Eliminar</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">Loading...</td>
                </tr>
              )}
            </tbody>
          </Table>

          <Modal show={showEditModal} onHide={handleCloseEditModal}>
            <Modal.Header closeButton>
              <Modal.Title>Actualizar información del inspirado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese el nombre"
                    value={editNombre}
                    onChange={(e) => setEditNombre(e.target.value)}
                  />
                </Col>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese el documento"
                    value={editCedula}
                    onChange={(e) => setEditCedula(e.target.value)}
                  />
                </Col>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese el teléfono"
                    value={editTelefono}
                    onChange={(e) => setEditTelefono(e.target.value)}
                  />
                </Col>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese el correo"
                    value={editCorreo}
                    onChange={(e) => setEditCorreo(e.target.value)}
                  />
                </Col>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese el usuario"
                    value={editUser}
                    onChange={(e) => setEditUser(e.target.value)}
                  />
                </Col>
                <Col>
                  <input
                    type="checkbox"
                    checked={editEstado === 1}
                    onChange={(e) => setEditEstado(e.target.checked ? 1 : 0)}
                    value={editEstado}
                  />
                  <label>Activo</label>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEditModal}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleUpdate}>
                Guardar
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </Fragment>
  );
};

export default CRUD;
