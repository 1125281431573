// src/Recursos.js
import React from 'react';
import './Recursos.css';

const Recursos = () => {
  return (
    <div className="recursos-container">
      <h1 className="main-title">Eco Inspira</h1>
      <p className="info-text">Aca deberia ir mucha información pero no se si se coloca aquí o en el css</p>
      <h2 className="secondary-title">Recursos</h2>
      <div className="content">
        <h3>Banalterna ECOInspira</h3>
        <p>Creando Oportunidades de Vida COOPERANTES</p>
        
        <h4>Qué es?</h4>
        <p>"La utopía está en el horizonte, camino dos pasos, ella se aleja dos pasos y el horizonte se corre diez pasos más allá, ¿entonces para qué sirve la utopía? Sirve para eso, para caminar" - Eduardo Galeano</p>
        <p>
          Es una propuesta de acción colaborativa que parte de la inquietud por dar solución a retos vitales de individuos y grupos, en el marco de lo que podríamos llamar “economía colaborativa”, para englobar conceptos como economía circular, trueque, intercambio de saberes y haceres, banco de tiempo, solidaridad, ecología humana, ambientalismo, empresa asociativa, cooperativismo, economía social, y cualquier otra propuesta que pueda ser utilizada para el propósito de construir soluciones colectivas autosostenibles y sin ánimo de lucro.
        </p>
        <p>
          Uno de los conceptos arriba citados, que ha surgido con especial énfasis desde el comienzo de la reflexión en reuniones informales de quienes nos hemos inquietado por el tema, es el de banco de tiempo. Un banco de tiempo es una red de intercambio de servicios que se basa en el tiempo como unidad de cambio. Los usuarios ofrecen y reciben distintos servicios entre sí, contabilizando el tiempo invertido y acumulando créditos que pueden canjear por otros servicios. Es una iniciativa comunitaria que busca fomentar la solidaridad, la cooperación y el apoyo mutuo entre las personas.
        </p>
        <p>
          Aunque el concepto de banco se ha utilizado corrientemente para designar la empresa que se encarga de la recepción, administración y préstamo de dinero, en este caso se trata de un modelo donde es posible el aporte y la circulación de posibilidades; un dispositivo con capacidad para convocar y provocar iniciativas, proyectos, tanto individuales como colectivos, en busca del bien común.
        </p>
        <p>El carácter de Eco e Inspirador surge de considerar que:</p>
        <ul>
          <li>Todo lo existente consciente y no consciente está en relación y deviene</li>
          <li>Incorpora el contexto y el medio como fundamento e inteligencia de la vida</li>
          <li>La vida es interdependencia y multiplicidad</li>
          <li>La interdependencia organizada crea sinergia</li>
          <li>Armoniza el ir siendo con el ir dinamizando las condiciones desde lo cultural y lo ético político</li>
          <li>Es necesaria la juntanza creadora para contrarrestar la competencia depredadora</li>
          <li>Lo concreto y sus obstáculos, es un desafío que se redefine desde lo abstracto y trascendente</li>
          <li>La crisis del sistema requiere el surgimiento y manifestación de propuestas y haceres alternativos</li>
        </ul>
        <p>
          La observación de los haceres de quienes hemos comenzado a integrar este grupo de soñadores, arroja que cada uno a su manera cumple con la actitud solidaria, el deseo de ayuda a otros, la presencia colaborativa a sus familias, amigos y comunidad; por eso la propuesta de Banco de Tiempo surge esencialmente como una forma de sistematizar esas acciones que ya todos de una u otra forma realizamos.  No obstante, no es la única ni es exclusivista, por lo que la reflexión del grupo de trabajo ha de aportar las posibilidades de interacción con otras propuestas de economía colaborativa.
        </p>

        <h4>Quiénes están llamados y con qué recursos?</h4>
        <p>“¿Cuándo hornearemos un pan del tamaño del mundo?”</p>
        <ul>
          <li>El tiempo de las personas</li>
          <li>La buena voluntad de las personas que conocemos</li>
          <li>Los que quieren hacer una vaina distinta y buscar sentido de otra manera</li>
          <li>Los excedentes de los que ya se sienten saciados. Benefactores</li>
          <li>Los usuarios bacanos</li>
          <li>Las ideas y su gestión</li>
          <li>Las comunidades</li>
          <li>La vibra vital</li>
          <li>Lo ya usado y desechado</li>
          <li>La confianza</li>
          <li>La esperanza</li>
          <li>La agudeza y el discernimiento</li>
          <li>El espacio disponible</li>
          <li>El cuidado de si, del otro y de lo otro</li>
          <li>La red y la nube</li>
        </ul>

        <h4>Principios de actuación</h4>
        <p>“Los principios nos han de perseguir hasta los finales”.</p>
        <ol>
          <li>
            <strong>Sin ánimo de lucro</strong><br />
            Tal y como es la naturaleza humana original, el móvil principal del trabajo por el bienestar y la felicidad del otro desde la ética o desde el amor, no es el interés monetario. Reconocemos que la vulnerabilidad es un elemento fundamental y afortunado de todo ser humano; afortunado por cuanto permite que alguien pueda suplir aquello en lo que somos vulnerables, y nos permite servir allí donde el otro lo es. Eso que no puedo otro si lo puede, eso que no sé otro si lo sabe, eso que no tengo otro si lo tiene, y viceversa.
          </li>
          <li>
            <strong>Acción colectiva solidaria</strong><br />
            Las articulaciones, los acuerdos y las redes solidarias y cooperativas, no son un invento nuevo ni alternativo, pues han existido probablemente desde el origen mismo de la humanidad. Ni el mercantilismo de las relaciones ha logrado terminar con el lazo humano como potenciador de su desarrollo.
          </li>
          <li>
            <strong>Acción focalizada</strong><br />
            Dar en el blanco con una escopeta de perdigones es más fácil pero destroza al blanco; dar en el blanco con un solo tiro es más difícil pero no desperdicia el valioso recurso. Caminar juntos y colocar en ese caminar las experiencias diversas y múltiples, supone que la comunidad no sea una masa de individuos, sino una sinergia potenciadora permanente del cambio necesario.
          </li>
          <li>
            <strong>Construcción de conocimiento transformador</strong><br />
            Los saberes que conforman, realizan y hacen posible el disfrute de la vida, parecen provenir al menos de dos grandes fuentes: el conocimiento acumulado histórico de todo aquello por lo que nuestros ancestros han debido cruzar para llegar a ser la humanidad actual, con sus resultados favorables y no favorables a su desarrollo, y la intuición, que proviene de los aspectos más elevados del ser y que nos lleva a la incorporación constante de lo nuevo con lo cual generar salidas a los constantes retos que suponen la existencia sobre el planeta.
          </li>
        </ol>

        <h4>En dónde vamos?</h4>
        <p>“A quien no sabe para dónde va, cualquier bus le sirve”</p>
        <p>
          Este gráfico presenta la topología del proceso global. Nos hemos encontrado para ver en cada uno qué hay, cómo funciona eso que hay, cómo potenciarlo y cómo sostenerlo. En ese encuentro de palabras y miradas vamos transitando una y otra vez por el reconocimiento de lo que se ve y lo que no se alcanza a ver, se van reconociendo y estableciendo las relaciones, se vislumbran las posibilidades bajo la mirada de los otros, y se va definiendo con qué instrumentos podemos ir materializando el sueño.
        </p>
        <p>“Tao da a luz a Uno, Uno (se divide y) da a luz a Dos, El Dos da a luz a Tres, El Tres da a luz a todas las cosas universales. (Tao the King)”</p>
        <p>
          Una idea ha surgido entre el corazón y la cabeza de alguien inquieta por hacer mejor el mundo. Se la comenta a otro y a otro, y estos tres otros van conversando un tinto del cual va surgiendo la posibilidad, el sueño, la utopía, de colectivizar la acción para el logro. Hoy somos trece y siempre abiertos a quien quiera estar, no por estar sino por ser, como potenciador de una idea transformadora del mundo desde lo pequeño, desde la acción frugal, modesta, tranquila. Que quiera servir y dejarse servir. Que sienta que tiene algo valioso que dar o quiera construirlo.
        </p>
        <p>
          Sin encuentro no hay noviazgo, por lo que para la generación de lo que viene es preciso encontrarse, escucharse, aprender a callar y a decir, mirarse a los ojos, tomarse un café, esperar, tolerar, dar lugar especial al error, reconocer el conocimiento y el saber propios y ajenos; informalmente se han planteado reuniones semanales que, cuando poquitos, resultaba fácil el calor de la presencialidad, y cuando más hartos más difícil tal encuentro presencial, precisamente por los haceres y deberes que definen el valioso derrotero de cada quien; entonces las herramientas virtuales resultan útiles aunque no tan deseables, pero somos comprensivos de tales circunstancias.
        </p>
        <p>
          Quien va llegando va presentando su hacer, su proyecto, su empresa, su sueño vital, su emprendimiento, y el contexto situacional en el que se mueve; eso nos ha permitido hacernos conscientes cada vez más de la necesidad de hacer esfuerzos colectivos para la solución de elementos vitales particulares, sobre la base de las posibles actuaciones solidarias.
        </p>
        <p>
          Como de la idea se llega al acto transformador de la vida y del mundo, y todo comienza siempre por la idea pero no debería quedarse solo allí aunque reconozcamos que pensar ya es crear, hemos venido lentamente aterrizando hacia la evaluación de cada uno de los proyectos o ideas que están sobre la mesa, ya sea que estén actuando ya como proyectos productivos particulares o como sueños de serlo, y pronto tendremos la posibilidad de mirarnos a través de herramientas administrativas concisas, que nos permitan definir mejor el qué, el cómo, el con qué, el cuánto y el cuándo; entonces a la vez que vamos consolidando la manera de administrar la propuesta Ecoinspira, vamos también revisando los haceres particulares para cualificarlos al máximo y así poder ser cada vez mejores instrumentos se servicio.
        </p>
        <p>
          Este documento pretende presentar el contexto con el cual vamos forjando la identidad que nos permita definir qué somos y qué no somos, y por ahora esperamos que sirva como simple referencia para quienes se inquieten en pertenecer. Quienes escriben no se toman el lugar más que de simples intérpretes cocreadores en vista de que las palabras vuelan y lo escrito permanece. Por lo tanto, puede ser alimentado con todo aporte, corrección y observación que siempre serán bienvenidas. Y como nadie llega tarde, solo llega después, ¡¡Siempre serán bienvenidos!!
        </p>
        <h2 className="secondary-title">Recursos Adicionales</h2>
      </div>
      <ul>
      <li><a href="https://medium.com/timerepublik/in-colombia-a-timebanking-revolution-blossoms-51de590c824" target="_blank" rel="noopener noreferrer">In Colombia: A Timebanking Revolution Blossoms</a></li>
        <li><a href="https://youtu.be/PPdvLTe0wjA?si=kGKKvsVrF-g3vbL6" target="_blank" rel="noopener noreferrer">Video 1: YouTube</a></li>
        <li><a href="https://youtu.be/SQvubWx-kF4?si=cefIsJp8OfDBQlUI" target="_blank" rel="noopener noreferrer">Video 2: YouTube</a></li>
        <li><a href="https://youtu.be/01gYfRr4boI?si=B-aHRCTsv6B-G-7a" target="_blank" rel="noopener noreferrer">Otros: YouTube</a></li>
        {/* Añade más recursos según sea necesario */}
      </ul>
    </div>
  );
};

export default Recursos;
