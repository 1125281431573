// src/Home.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fondosol from './fondosol.png';
import './Home.css';

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userName } = location.state || { userName: 'Usuario' };
  const [expandedModule, setExpandedModule] = useState(null);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const toggleModule = (module) => {
    setExpandedModule(expandedModule === module ? null : module);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleResourcesClick = () => {
    navigate('/recursos');
  };

  return (
    <div className="home-container" style={{ backgroundImage: `url(${fondosol})` }}>
      <h2>Hola <span className="user-name">{userName}</span>, Bienvenido a ECO INSPIRA</h2>
      <div className="modules-container">
        <div className="module" onClick={() => toggleModule('emprendimiento')}>
          <h3>Servicios para el emprendimiento</h3>
          {expandedModule === 'emprendimiento' && (
            <div className="module-options">
              <button onClick={() => handleNavigate('/proyectos-en-desarrollo')} className="menu-button">Proyectos en desarrollo</button>
              <button onClick={() => handleNavigate('/circulosapoyo')} className="menu-button">Comparte tu proyecto</button>
              <button onClick={() => handleNavigate('/eventos-encuentros')} className="menu-button">Eventos y encuentros</button>
            </div>
          )}
        </div>

        <div className="module" onClick={() => toggleModule('personas')}>
          <h3>Servicios para las personas</h3>
          {expandedModule === 'personas' && (
            <div className="module-options">
              <button onClick={handleLoginClick} className="menu-button">Tu cuenta personal</button>
              <button onClick={() => handleNavigate('/inscribir')} className="menu-button">Tramita tu cuenta fácil</button> {/* Redirigir a CRUD */}
            </div>
          )}
        </div>

        <div className="module" onClick={() => toggleModule('comunidades')}>
          <h3>Servicios para Comunidades</h3>
          {expandedModule === 'comunidades' && (
            <div className="module-options">
              <button onClick={() => handleNavigate('/los-mayores')} className="menu-button">Los mayores</button>
              <button onClick={() => handleNavigate('/cuidadores')} className="menu-button">Cuidadores</button>
            </div>
          )}
        </div>
      </div>
      <button onClick={handleResourcesClick} className="resources-button">Más información</button>
    </div>
  );
};

export default Home;
