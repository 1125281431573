// src/Inscribir.js
import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from './firebase';
import { setDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import './Inscribir.css';

const Inscribir = () => {
  const navigate = useNavigate();

  const [nombre, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [user, setUser] = useState('');
  const [estado, setEstado] = useState(1);

  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState('');

  const [data, setData] = useState(null);

  const handleGuardarIns = async () => {
    const fechaInscripcion = new Date().toLocaleDateString('es-ES');
    const data = {
      nombre,
      cedula,
      telefono,
      correo,
      user,
      estado,
      fechaInscripcion,
      score: 3 // Agregar el campo score con valor inicial 3
    };

    if (!nombre || !cedula || !telefono || !correo || !user) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await setDoc(doc(db, 'inspirados', cedula), data);
      setData(data);
      clear();
      toast.success('Inspirado agregado con éxito');
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error('Error al agregar inspirado');
    }
  };

  const handleUpdate = async () => {
    const inspiradoRef = doc(db, 'inspirados', editId);
    const updatedData = {
      nombre,
      cedula,
      telefono,
      correo,
      user,
      estado
    };

    if (!nombre || !cedula || !telefono || !correo || !user) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      await updateDoc(inspiradoRef, updatedData);
      setData(updatedData);
      setEditMode(false);
      toast.success('Inspirado actualizado con éxito');
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error al actualizar inspirado');
    }
  };

  const handleBorrar = async () => {
    if (window.confirm("¿Deseas eliminar a este inspirado?")) {
      try {
        await deleteDoc(doc(db, 'inspirados', data.cedula));
        setData(null);
        toast.success('Inspirado eliminado con éxito');
      } catch (error) {
        console.error('Error deleting document: ', error);
        toast.error('Error al eliminar inspirado');
      }
    }
  };

  const clear = () => {
    setNombre('');
    setCedula('');
    setTelefono('');
    setCorreo('');
    setUser('');
    setEstado(1);
  };

  const handleEdit = (inspirado) => {
    setEditMode(true);
    setNombre(inspirado.nombre);
    setCedula(inspirado.cedula);
    setTelefono(inspirado.telefono);
    setCorreo(inspirado.correo);
    setUser(inspirado.user);
    setEstado(inspirado.estado);
    setEditId(inspirado.cedula);
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <Row className="mb-3">
          <Col>
            <Button variant="danger" onClick={() => navigate('/')}>Regresar</Button>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label>Nombre:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escribe tu nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Col>
          <Col md={6}>
            <label>Documento:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escribe tu documento"
              value={cedula}
              onChange={(e) => setCedula(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label>Teléfono:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escribe tu teléfono"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </Col>
          <Col md={6}>
            <label>Correo:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escribe tu correo"
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label>Usuario:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escribe tu usuario"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {editMode ? (
              <Button variant="primary" onClick={handleUpdate}>Actualizar</Button>
            ) : (
              <Button variant="primary" onClick={handleGuardarIns}>Guardar</Button>
            )}
          </Col>
        </Row>
      </Container>
      <br />
      {data && (
        <>
          <h3>Información del Inspirado</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cédula</th>
                <th>Teléfono</th>
                <th>Correo</th>
                <th>Usuario</th>
                <th>Estado</th>
                <th>Score</th>
                <th>Fecha de Inscripción</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.nombre}</td>
                <td>{data.cedula}</td>
                <td>{data.telefono}</td>
                <td>{data.correo}</td>
                <td>{data.user}</td>
                <td>{data.estado}</td>
                <td>{data.score}</td>
                <td>{data.fechaInscripcion}</td>
                <td>
                  <Button variant="primary" onClick={() => handleEdit(data)}>Editar</Button>
                  &nbsp;
                  <Button variant="danger" onClick={handleBorrar}>Eliminar</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </Fragment>
  );
};

export default Inscribir;
